import React from "react";

// Lazy load with error handling for the import
export const lazyWithErrorHandling = importFunc =>
  React.lazy(() =>
    importFunc().catch(error => {
      console.warn("Failed to load component:", error);
      return {
        default: () => (
          <div className="w-full h-screen flex flex-col items-center justify-center">
            <p className="font-semibold text-lg text-warning mb-1">
              Loading failed
            </p>
            <p className="font-medium text-center px-2">
              Please check your internet connection and try again
            </p>
          </div>
        ),
      };
    })
  );
